import React from 'react'
import Layout from '../components/layout'
import {navigate} from 'gatsby'

function MeetPage (props) {

  const openLogin = () => {
    navigate('/auth');
  }

  const getStartedCTA = () => {
    return <button className={"w-full lg:w-[unset] bg-denim text-white py-2 px-10 font-bold lg:font-semibold text-2xl lg:text-base rounded-sm"}
                   onClick={openLogin}>GET STARTED</button>
  }

  const getCTA = (cta) => {
    return <button className={"w-full lg:w-[unset] border-denim border-[1px] text-denim py-2 px-10 font-bold lg:font-semibold text-2xl lg:text-base rounded-sm"}
                   onClick={openLogin}>{cta}</button>
  }

  const getHeroSectionText = () => {
    return <div className={"text-center pt-2 lg:pt-0 lg:text-left lg:pl-16"}>
      <p className={"font-bold text-2xl mb-3 leading-snug max-w-[418px]"}>A safe place to meet and connect with old and new friends</p>
      <p className={"text-gray-700 leading-snug max-w-[418px] mb-5"}>A safe environment where senior citizens can form friendships, engage in responsible conversations, and enjoy privacy.</p>
      {getStartedCTA()}
    </div>
  }

  const getFeatureTile = (icon, text) => {
    return <div className={"flex border-denim px-3 lg:px-6 py-1 lg:py-3 text-denim text-sm lg:text-xl rounded-sm items-center border-2 leading-tight uppercase"}>
      <img className={"max-w-[35px] lg:max-w-[48px]"} src={`/images/age-meet/icons/${icon}`} width={56} height={56} alt={text}/>
      <p className={"font-semibold ml-2 lg:ml-8 mb-0"}>{text}</p>
    </div>
  }

  return (
    <Layout>
      <main className="wrapper main-data-container" role="main" data-datocms-noindex>
        <div className={'bg-meet-hero bg-right lg:bg-center min-h-[360px] flex items-center lg:-mt-10'}>
          <div className={"hidden lg:block"}>
            {getHeroSectionText()}
          </div>
        </div>
        <div className={"lg:hidden"}>
          {getHeroSectionText()}
        </div>
        <div className={"grid lg:grid-cols-4 grid-cols-2 gap-4 mt-4"}>
          {getFeatureTile('meet-friends.png', 'meet friends')}
          {getFeatureTile('secure-env.png', 'Secure environment')}
          {getFeatureTile('free.png', '100% free')}
          {getFeatureTile('people-4.png', 'up to 4 people')}
        </div>
        <div className={'grid lg:grid-cols-2 gap-4 mt-8'}>
          <img src={'/images/age-meet/get-connected.png'}/>
          <div className={"flex flex-col m-auto text-center lg:text-left items-start"}>
            <h3 className={"font-bold"}>Get Connected</h3>
            <p className={"max-w-[418px] leading-snug"}>Agetimes provides elderly people with a common ground to communicate and share experiences and emotions without feeling isolated in modern society.</p>
            {getCTA('MEET NEW FRIENDS')}
          </div>
        </div>
        <div className={'lg:grid lg:grid-cols-2 flex flex-col-reverse gap-4 mt-8'}>
          <div className={"flex flex-col m-auto text-center lg:text-left items-start"}>
            <h3 className={"font-bold"}>Safe and Secure</h3>
            <p className={"max-w-[418px] leading-snug"}>We offer an appropriate video chat platform that enables senior citizens to make new friends in a secure and safe environment. We value privacy, and we assure you that your information is safe with us.</p>
            {getCTA('CREATE YOUR ACCOUNT')}
          </div>
          <img src={'/images/age-meet/safe-secure.png'}/>
        </div>
        <div className={"mt-8"}>
          <img src={'/images/age-meet/people-image.png'}/>
        </div>
        <div className={"text-center max-w-[890px] m-auto mt-5 mb-8"}>
          <h3 className={"font-bold"}>A secure community of people just like you</h3>
          <p>Find people with whom you have common interests, likes, and ideas. It's a great place to meet people who share common perspectives.</p>
          {getStartedCTA()}
        </div>
      </main>
    </Layout>
  )
}

export default MeetPage
